import React, { useState, useContext, useEffect } from 'react';
import { Form } from "react-bootstrap";
import Stripe from '../../../../../dist/images/PayGateways/stripe.png';
import Voucher from '../../../../../dist/images/PayGateways/voucher_small.png';
import { useQuery } from '@apollo/client';
import { cartList } from "./ShopService";
import { ContSerVcr } from "../../SingleShop";
import { toast } from 'react-toastify';

//API
import { VOUCHER_QUERY, VOUCHER_LIST } from "../../../../../gql/Query";
import { totalPayable } from '../../../system/utility';
import GooglePayment from './GooglePayment';

const PWays = ({ serviceAmount, upfront, totalSpecialUpfront, totalServiceAmount, specialDepositePayWithVettingCall, redeem, onPaySelect, currency, redeemMsg, applyRedeem, totalVPrice, paymentType }) => {
    const { singleShop } = useContext(ContSerVcr);
    const b_id = singleShop?.single_business?.business?.id
    // console.log("b_id", b_id)
    const {cartItems} = useContext(cartList);
    const [showField, setShowField] = useState(false);
    const [voucherShow, setVoucherShow] = useState(false);
    const [vcode, setVcode] = useState("");
    const [voucher_list, setVoucherList] = useState({});
    const [voucherValue, seVouchertValue] = useState(0);
    var [serviceList, setServiceList] = useState({})
    const srvUpfront = specialDepositePayWithVettingCall ? upfront :   +totalPayable(serviceAmount, upfront) + totalSpecialUpfront;
    const { loading, refetch } = useQuery(VOUCHER_QUERY, {
        variables: {
            voucher_code: vcode,
            selling_service: serviceList,
            grand_total: srvUpfront.toString(),
            guest_id: 0,
            discount: "",
            business_id: Number(b_id)
        }
    })

    //----------- voucher code list---------------
    const { data: vList } = useQuery(VOUCHER_LIST, {
        variables: {
            business_id: parseInt(b_id)
        }
    })

    useEffect(() => {
        const isService = () => {
            for (let cartItem of cartItems) {
                if (cartItem?.__typename === "ServicePricing") {
                    // console.log(rating.label)
                    setVoucherShow(true);
                    break;
                }
            }
        }
        const formatService = () => {
            var serviceArr = []
            cartItems.filter((item) => item?.__typename === "ServicePricing").map((x) => {
                return serviceArr.push({ service_pricing_id: parseInt(x.id), service_id: x.service_id, service_qty: x.qty, unit_price: x.price.toString(), special_price: x.special_price ? x.special_price.toString() : "" });
            })
            return serviceArr;
        }
        if (cartItems) {
            isService();
            setServiceList(formatService);
        }
        if (vList) {
            setVoucherList(vList.business_wise_voucher)
            // console.log("vList.business_wise_voucher?.length", vList.business_wise_voucher?.length)
        }
    }, [cartItems, vList]);

    const getVoucher = () => {
        if (vcode === "0") {
            toast.error("Please select a valid voucher code!")
        } else {
            refetch()
                .then(({ data }) => {
                    if (data) {
                        seVouchertValue(Number(data.myVoucher?.value))
                        redeem(vcode, Number(data.myVoucher?.value), (srvUpfront - Number(data.myVoucher?.value) + totalVPrice))
                        if (data.myVoucher?.status === 1) {
                            if (data.myVoucher?.value > 0) {
                                toast.success(data.myVoucher?.message);
                            } else {
                                toast.info("You have reached the limit!");
                            }
                        } else {
                            toast.error("Voucher is not applicable!");
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                    toast.error("Internal server error!");
                })
        }
    }
    return (
        <>
            <div className='pt-5 p-way'>
                <div className='d-flex align-items-center justify-content-center mb-3 me-2'>
                    <input className='form-check-input' type="radio" id="stripe" name="p_way" checked={paymentType === "stripe" ? true : false}
                        onChange={() => { setShowField(false); onPaySelect("stripe", applyRedeem) }} />
                    <label className='ms-3' htmlFor="stripe"><img src={Stripe} alt="stripe" /></label>
                </div>
                <div className='d-flex align-items-center justify-content-center mb-3'>
                    <input className='form-check-input' type="radio" id="g-pay" name="p_way" checked={paymentType === "google" ? true : false}
                        onChange={() => { setShowField(false); onPaySelect("google", applyRedeem) }} />
                    <label className='ms-3 g_pay_label' onClick={() => { setShowField(false); onPaySelect("google", applyRedeem) }} htmlFor="g-pay">
                        <GooglePayment amount={ srvUpfront } />
                    </label>
                </div>
            </div>
            {
                voucherShow &&
                <div className='d-flex align-items-center p-way justify-content-center'>
                    <input className='form-check-input' type="radio" id="voucher" name="p_way"
                        checked={paymentType === "voucher" ? true : false}
                        onChange={() => { setShowField(!showField); onPaySelect("voucher", applyRedeem) }} />
                    <label className='ms-3' htmlFor="voucher">
                        <img src={Voucher} alt="voucher" />
                    </label>
                </div>
            }
            {showField &&
                <div className="chuez-modal">
                    <div className="d-md-flex justify-content-center p-3 font-chzday rounded">
                        <>
                            <div className='me-2'>
                                <select className='form-control' onChange={(e) => { setVcode(e.target.value) }}>
                                    <option value="0">Select a voucher code</option>
                                    {
                                        voucher_list?.length > 0 &&
                                        voucher_list.map((item) => (
                                            <option key={item?.voucher_code} value={item?.voucher_code}>
                                                {item?.voucher_code}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="me-2">
                                <Form.Control
                                    className='p-2'
                                    type="text"
                                    readOnly
                                    value={`${currency}${voucherValue}`}
                                />
                            </div>
                            <div>
                                <button className='mt-1 btn-primary border-primary border-0 p-3' disabled={loading ? true : false}
                                    onClick={() => { getVoucher() }}>Redeem</button>
                            </div>
                        </>
                    </div>
                    {
                        redeemMsg &&
                        <p className='video-vetting-box p-3 m-5 shadow'>{redeemMsg}</p>
                    }
                </div>
            }
        </>
    )
}

export default PWays
